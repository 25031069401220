import React from "react";
import { useParams } from "react-router-dom";
import TimeCountDown from "../countdown";
// import hImg1 from '../../images/slider/shape.png'
// import hImg2 from '../../images/slider/shape2.png'
import hero from "../../images/slider/header-2.jpg";
// import hero from "../../images/slider/smile-1.jpg";
import afternoon from "../../images/slider/afternoon-tea-slide.jpg";
import hImg4 from "../../images/slider/shape3.png";
import hImg5 from "../../images/slider/shape4.png";

function createRsvpName(name) {
  if (name == null) {
    return;
  }
  return (
    <>
      <div
        className="slide-text"
        style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          paddingBottom: "20px"
        }}
      >
        For <h3>{name}</h3>
      </div>
    </>
  );
}

const Hero = (props) => {
  const { namepart } = useParams();
  let rsvpName = null;
  if (namepart != null) {
    rsvpName = namepart.split("_").join(" ");
  }

  return (
    <section className="static-hero">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div
                className="col-xl-8 col-lg-6 col-12"
                // style={{ width: "50%" }}
              >
                <div className="wpo-static-hero-inner">
                  {/* <div className="shape-1"><img src={hImg1} alt="hImg1"/></div> */}
                  {createRsvpName(rsvpName)}
                  <div
                    data-swiper-parallax="300"
                    className="slide-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <h2>Save the date</h2>
                  </div>
                  <div
                    className="slide-text"
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center"
                    }}
                  >
                    <p style={{ paddingBottom: "40px" }}>
                      Jane & Anton Are Getting Married
                    </p>
                    <div style={{ fontSize: "20px" }}>
                      01 October, 2022 - Tuscany, Italy
                    </div>
                  </div>
                  <div className="wpo-wedding-date">
                    <div
                      className="clock-grids"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <TimeCountDown />
                    </div>
                  </div>
                  {/* <div className="shape-2"><img src={hImg2} alt="hImg2"/></div> */}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static-hero-right">
        <div className="static-hero-img">
          <div className="static-hero-img-inner">
            <img src={hero} alt="jane and anton" />
          </div>
          {/* <div className="static-hero-shape-1">
            <img src={hImg4} alt="hImg4" />
          </div>
          <div className="static-hero-shape-2">
            <img src={hImg5} alt="hImg5" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
