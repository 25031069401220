import React, { Component } from "react";
import { Link } from "react-router-dom";
import coupleImg1 from "../../images/couple/1.jpg";

const boxStyle = {
  padding: "10px"
};

const bottomPad = {
  paddingBottom: "20px"
};

class CoupleSection extends Component {
  render() {
    return (
      <section className="couple-section" style={{ paddingBottom: "60px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xs-12">
              <div className="couple-area clearfix">
                <div className="text-grid bride">
                  <div style={bottomPad}>
                    <h3>Lorenzo Village, Tuscany</h3>
                    <p>Loc Via Pian di Campo, 10, 53036 Poggibonsi SI, Italy</p>
                    <p>
                      <a
                        href="https://goo.gl/maps/9DXJXbC74h1DG8dB8"
                        target="_blank"
                      >
                        View on Google Maps
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.youtube.com/watch?v=zX4jrkE6Tuw"
                        target="_blank"
                      >
                        on Youtube
                      </a>
                    </p>
                  </div>
                  <div style={bottomPad}>
                    <h3>Closest Airports</h3>
                    <p>Florence Airport (63km)</p>
                    <p>Pisa Airport (90km)</p>
                  </div>
                  <div style={bottomPad}>
                    <h3>Accomodation</h3>
                    <p>
                      We have a very limited amount of rooms available onsite -
                      which are now all reserved :(, but there are also plenty
                      of options around.
                    </p>
                    <p>
                      <a
                        href="https://www.google.de/maps/search/hotels/@43.4405647,11.1430806,14z/data=!3m1!4b1!4m5!2m4!5m3!5m2!1s2022-05-12!2i2"
                        target="_blank"
                      >
                        Hotels on Google Maps
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.airbnb.co.uk/s/Campostaggia--Province-of-Siena--Italy/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_dates%5B%5D=april&flexible_trip_dates%5B%5D=may&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&query=Campostaggia%2C%20Province%20of%20Siena%2C%20Italy&place_id=ChIJuSEgeW46KhMR27D1KP0T4Og&checkin=2022-09-30&checkout=2022-10-02&adults=2&source=structured_search_input_header&search_type=autocomplete_click"
                        target="blank"
                      >
                        Airbnb
                      </a>
                    </p>
                    <p>
                      <strong>Recommended by Lorenzo Village</strong>
                      <div>
                        Villa San Lucchese, Podere il Pino, Hotel Palazzo San
                        Lorenzo, Hotel Alcide, Hotel Villa San Giorgio, i
                        Melograni del Chianti
                      </div>
                    </p>
                  </div>
                </div>
                <div className="text-grid groom">
                  <h3>Program</h3>
                  <div style={boxStyle}>
                    <h5>Friday, 30 Sept</h5>
                    <li>From 10:00: Guests arrive</li>
                    <li>From 16:30: Pizza Garden Party</li>
                  </div>
                  <div style={boxStyle}>
                    <h5>Saturday, 01 Oct</h5>
                    <li>Afternoon: Ceremony</li>
                    <li>18:30: Dinner</li>
                    <li>22:00: Party</li>
                  </div>
                  <div style={boxStyle}>
                    <h5>Sunday, 02 Oct</h5>
                    <li>11:00: Checkout at Lorenzo Village</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ paddingTop: "25px", height: "unset" }}
        >
          <div className="row align-items-center">
            <div className="col col-xs-12">
              <div className="couple-area clearfix">
                <div
                  style={{
                    paddingBottom: "25px",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    textAlign: "center"
                  }}
                >
                  <h3>Location</h3>
                  <p>
                    Lorenzo Village is a beautiful old borgo/hamlet which has
                    been converted into apartments and rooms. The setting is
                    perfect in the heart of Tuscany between Siena and Florence,
                    with easy access to the motorway, allowing guests to be able
                    to visit some of Tuscany's most idyllic villages, historic
                    towns and wonderful cities and countryside.
                  </p>
                  <p>
                    You are surrounding by magical Tuscan landscapes of rolling
                    hills, cypress trees, olive groves and vineyards. You can
                    visit some of the classic Chianti wine villages within half
                    an hour such as Castellina in Chianti (19km) and Radda in
                    Chianti (30km) or drive just over an hour south to the
                    glorious countryside around the famous towns of
                    Montepulciano (88km) and Cortona (99km).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoupleSection;
