import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import Protect from "react-app-protect";

import "react-app-protect/dist/index.css";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";

// https://emn178.github.io/online-tools/sha512.html
const SCODE =
  "9b71d224bd62f3785d96d46ad3ea3d73319bfbc2890caadae2dff72519673ca72323c3d99ba5c11d7c7acc6e14b8c5da0c4663475c2e5c3adef46f73bcdec043";

const App = () => {
  console.log("hi - please don't rainbow table me.");

  return (
    <Protect
      blur
      boxTitle="Hello, how's it going?"
      inputPlaceholder="Invite Code"
      buttonLabel="Let's go"
      sha512={SCODE}
    >
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    </Protect>
  );
};

export default App;
