import React from "react";
import { Link } from "react-router-dom";
import Projects from "../../api/projects";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <p>Welcome to our amazing journey</p>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Contact</h3>
                  <div>Please reach out if you have any questions</div>
                  <ul>
                    <li>
                      <a href="mailto:janey.e.noble@gmail.com">
                        janey.e.noble@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3></h3>
                </div>
                <div className="contact-ft">
                  <p></p>
                </div>
              </div>
            </div>
            {/* 
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget instagram">
                <div className="widget-title">
                  <h3>Instagram</h3>
                </div>
                <ul className="d-flex">
                  {Projects.slice(0, 6).map((project, pitem) => (
                    <li key={pitem}>
                      <Link
                        onClick={ClickHandler}
                        to={`/project-single/${project.id}`}
                      >
                        <img src={project.pimg1} alt="" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright"> &copy; 2022 Jane and Anton</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
