import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/hero";
import StorySection from "../../components/StorySection";
import CoupleSection from "../../components/CoupleSection";
import CoupleSection2 from "../../components/CoupleSection2";
import VideoSection from "../../components/VideoSection";
import PortfolioSection2 from "../../components/PortfolioSection2";
import EventSection from "../../components/EventSection";
import RSVP from "../../components/RSVP";
import BlogSection from "../../components/BlogSection";
import Scrollbar from "../../components/scrollbar";
import PartnerSection from "../../components/PartnerSection";
import Footer from "../../components/footer";
import PlannerPage from "../PlannerPage";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-site-header-s1"} />
      <Hero />
      <StorySection />
      {/* <PortfolioSection2 /> */}
      {/* <PartnerSection /> */}
      {/* <CoupleSection2 /> */}
      <EventSection />
      <CoupleSection />
      <VideoSection />
      <RSVP />
      {/* <BlogSection/> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
