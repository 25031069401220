import React from "react";
import SectionTitle from "../SectionTitle";
// import lorenzo from "../../images/event/lorenzo.jpg";
import lorenzo from "../../images/event/lorenzo-outside-event.jpg";
import ceremony from "../../images/event/ceremony.jpg";
import theparty from "../../images/event/theparty.jpg";

const Events = [
  {
    Simg: lorenzo,
    title: "Pizza Party",
    li1: "Friday, 30 September, 2022 From 4:30 PM",
    li2: ""
  },
  {
    Simg: ceremony,
    title: "The Ceremony",
    li1: "Saturday, 01 October, 2022 Afternoon",
    li2: ""
  },
  {
    Simg: theparty,
    title: "Dinner & Party",
    li1: "Saturday, 01 October, 2022 6:30 PM",
    li2: ""
  }
];

const EventSection = (props) => {
  return (
    <section
      className={`wpo-event-section section-padding ${props.eClass}`}
      id="events"
    >
      <div className="container">
        <SectionTitle MainTitle={"The Location: Lorenzo Village"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <div className="wpo-event-item">
                  <div className="wpo-event-img">
                    <img src={event.Simg} alt="" />
                  </div>
                  <div className="wpo-event-text">
                    <h2>{event.title}</h2>
                    <ul>
                      <li>{event.li1}</li>
                      {/* <li>{event.li2}</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
