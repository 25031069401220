import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import oldQueen from "../../images/story/old-queen.jpg";
import smokehouse from "../../images/story/smokehouse.jpg";
import closmaggiore from "../../images/story/closmaggiore.jpg";
import dukeofwellington from "../../images/story/dukeofwellington-2.jpeg";

import sIcon1 from "../../images/story/1.png";
import sIcon2 from "../../images/story/2.png";
import sIcon3 from "../../images/story/3.png";
import sIcon4 from "../../images/story/4.png";

import Shape from "../../images/story/shape.jpg";

const StorySection = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const Tabs = [
    {
      Id: "1",
      tabItem: "First Meet"
    },
    {
      Id: "2",
      tabItem: "First Date"
    },
    {
      Id: "3",
      tabItem: "Marriage Proposal"
    },
    {
      Id: "4",
      tabItem: "Engagement Party"
    }
  ];

  const Story = [
    {
      Id: "1",
      sImg: oldQueen,
      sIcon: sIcon1,
      Shape: Shape,
      sTitle: "First Time We Met",
      date: "June 12, 2021",
      description: (
        <a href="https://g.page/oldqueenshead?share" target="_blank">
          The Old Queens Head, Essex Road
        </a>
      )
    },
    {
      Id: "2",
      sImg: smokehouse,
      sIcon: sIcon2,
      Shape: Shape,
      sTitle: "First Date",
      date: "June 16, 2021",
      description: (
        <a href="https://g.page/SmokehouseN1?share" target="_blank">
          The Smokehouse, Canonbury
        </a>
      )
    },
    {
      Id: "3",
      sImg: closmaggiore,
      sIcon: sIcon3,
      Shape: Shape,
      sTitle: "Marriage Proposal",
      date: "March 12, 2022",
      description: (
        <a href="https://www.closmaggiore.com/" target="_blank">
          Clos Maggiore, Covent Garden
        </a>
      )
    },
    {
      Id: "4",
      sImg: dukeofwellington,
      sIcon: sIcon4,
      Shape: Shape,
      sTitle: "Engagement Party",
      date: "May 07, 2022",
      description: (
        <a href="https://goo.gl/maps/aWxCwJKXak81N6ZD7" target="_blank">
          The Duke of Wellington
        </a>
      )
    }
  ];

  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle MainTitle={"Our story"} />

        <div className="row align-items-center justify-content-center">
          <div className="col col-lg-12 col-12">
            <div className="tab-area">
              <div className="tablinks">
                <Nav tabs className="service-thumbs-outer">
                  {Tabs.map((tab, titem) => (
                    <NavItem key={titem}>
                      <NavLink
                        className={`service-thumb ${classnames({
                          active: activeTab === tab.Id
                        })}`}
                        onClick={() => {
                          toggle(tab.Id);
                        }}
                      >
                        {tab.tabItem}
                        <span className="number">{tab.tabNumber}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                {Story.map((story, Sitem) => (
                  <TabPane tabId={story.Id} key={Sitem}>
                    <div className="wpo-story-item">
                      <div className="wpo-story-img">
                        <img src={story.sImg} alt="" />
                      </div>
                      <div className="wpo-story-content">
                        <div className="wpo-story-content-inner">
                          <span>
                            <img src={story.sIcon} alt="" />
                          </span>
                          <h2>{story.sTitle}</h2>
                          <span>{story.date}</span>
                          <p>{story.description}</p>
                          {/* <div className="border-shape">
                            <img src={story.Shape} alt="" />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      <StoryText />
    </section>
  );
};

function StoryText() {
  return (
    <div className="container" style={{ height: "unset", paddingTop: "30px" }}>
      <div className="row align-items-center">
        <div className="col col-xs-12">
          <div
            className="couple-area clearfix"
            style={{ backgroundColor: "#fbf8f8" }}
          >
            <div style={{ padding: "25px", textAlign: "center" }}>
              <h3 style={{}}>Jane & Anton</h3>
              <p>
                Their story starts out like a lot of London couple's do… in a
                Pub! Anton was out on a boys pub crawl and Jane was trying to
                have a nice afternoon catching up with her girlfriends. The
                girls eventually went home and Jane wasn't ready to leave so she
                hung out with the boys for a few more hours.
              </p>
              <p>
                After getting along soooo well they went out for BBQ (with
                German beer) a few days later and bonded over their shared love
                of New Zealand, good food and{" "}
                <a
                  href="https://www.itl.cat/pngfile/big/59-599186_download-quote-image-here-harvey-specter-quotes-work.jpg"
                  target="_blank"
                >
                  Harvey Specter
                </a>
                . It really only took that one date to know they had something
                special.
              </p>
              <p>
                Soon after Jane moved in, they were stuck indoors together with
                Covid and had the time of their lives; bingeing Game of Thrones,
                playing Xbox, buying a house and eating Filet with peppercorn
                sauce (Safe to say, Anton has now perfected it).
              </p>
              <p>
                On 12th March, 9 months after meeting, Anton took Jane out to
                the lovely{" "}
                <a href="https://www.closmaggiore.com/" target="_blank">
                  Clos Maggiore
                </a>{" "}
                (aka 'most romantic restaurant in London') and when they got
                home, he got down on one knee and asked Jane to marry him. She
                quickly said YES! It was the easiest question she's ever
                answered.
              </p>
              <p>
                <strong>
                  Our shared love of the country, first holiday destination
                  together and Anton's obsession (!!!) with pasta, lead us to
                  Italy being the most obvious wedding destination.
                </strong>
              </p>
              <p>
                <strong>
                  We feel very blessed to have such wonderful friends and family
                  in our lives and hope you can join us in Tuscany later this
                  year.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorySection;
