//https://sheet.best/admin/connection-detail/c145384a-65a7-4e96-ae0c-0028f505e115
const sheetsEndpoint =
  "https://sheet.best/api/sheets/c145384a-65a7-4e96-ae0c-0028f505e115";

export async function sendRsvp(data) {
  console.log("Sending data: ", data);
  const response = await fetch(sheetsEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  console.log(response);
  const json = await response.json();
  console.log(json);
}
