import React, { Component } from "react";
import MobileMenu from "../MobileMenu";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";

class Header extends Component {
  render() {
    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <header className={this.props.topbarBlock} id="home">
        <HeaderTopbar />
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div
              className="container-fluid"
              style={{ justifyContent: "center" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  {/* <div className="mobail-menu">
                    <MobileMenu />
                  </div> */}
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <NavLink
                      onClick={ClickHandler}
                      className="navbar-brand logo"
                      to="/"
                      style={{ marginRight: 0 }}
                    >
                      J & A
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li>
                        <Link
                          activeClass="active"
                          to="home"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="story"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Story
                        </Link>
                      </li>
                      {/* <li><Link activeClass="active" to="gallery" spy={true} smooth={true} duration={500} >Gallery</Link></li> */}

                      <li>
                        <Link
                          activeClass="active"
                          to="events"
                          spy={true}
                          smooth={true}
                          offset={-80}
                          duration={500}
                        >
                          Location
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="RSVP"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          RSVP
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
