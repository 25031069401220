import React, { Component } from "react";
import SectionTitle from "../../components/SectionTitle";

import vec1 from "../../images/contact/1.png";
import vec2 from "../../images/contact/2.png";
import { sendRsvp } from "./useSendRsvp";

class RSVP extends Component {
  state = {
    name: "",
    email: "",
    meal: "",
    guest: "",
    songs: "",
    accomodation: "",
    error: {},
    success: null,
    loading: false
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error
    });
  };

  subimtHandler = (e) => {
    e.preventDefault();

    const { name, email, meal, guest, songs, accomodation, error } = this.state;

    if (name === "") {
      error.name = "Please enter your name";
    }
    if (guest === "") {
      error.guest = "Please mark your attendence";
    }

    if (error) {
      this.setState({
        error
      });
    }

    if (error.name === "" && error.guest === "") {
      this.setState({ loading: true });
      sendRsvp({ name, email, meal, guest, songs, accomodation }).then(
        (res) => {
          this.setState({
            name: "",
            email: "",
            meal: "",
            guest: "",
            songs: "",
            accomodation: "",
            error: {},
            loading: false,
            success: `Thank you ${name}, see you soon!`
          });
        }
      );
    }
  };

  render() {
    const {
      name,
      email,
      guest,
      meal,
      songs,
      accomodation,
      error,
      success,
      loading
    } = this.state;

    return (
      <section className={`wpo-contact-section section-padding`} id="RSVP">
        <div className="container">
          <div className="wpo-contact-section-wrapper">
            <div className="wpo-contact-form-area">
              <SectionTitle MainTitle={"RSVP"} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "mediumvioletred",
                  fontWeight: "bold"
                }}
              >
                Please RSVP by 31. May
              </div>
              {/* <form onSubmit={this.subimtHandler} className="form">
                <div className="row">
                  <div>
                    <div className="form-field">
                      <input
                        value={name}
                        onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Names"
                      />
                      <p>{error.name ? error.name : ""}</p>
                    </div>
                  </div>
                  <div>
                    <div className="form-field">
                      <select
                        name="guest"
                        className="form-control"
                        value={guest}
                        onChange={this.changeHandler}
                      >
                        <option>Can you make it?</option>
                        <option>yes</option>
                        <option>YES!</option>
                        <option>Unfortunately not :(</option>
                      </select>
                      <p>{error.guest ? error.guest : ""}</p>
                    </div>
                  </div>
                  <div>
                    <div className="form-field">
                      <input
                        onChange={this.changeHandler}
                        value={email}
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-field">
                      <input
                        onChange={this.changeHandler}
                        value={meal}
                        type="meal"
                        className="form-control"
                        name="meal"
                        placeholder="Any Dietary Requirements"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-field">
                      <small>GOT A SONG YOU WANNA DANCE TO?</small>
                      <input
                        onChange={this.changeHandler}
                        value={songs}
                        type="songs"
                        className="form-control"
                        name="songs"
                        placeholder="Music Request"
                      />
                    </div>
                  </div>
                  
                  <div className="submit-area">
                    <div className="form-submit">
                      {success == null && (
                        <button
                          type="submit"
                          className="theme-btn-s3"
                          disabled={loading}
                        >
                          Send RSVP{`${loading ? "(Loading)" : ""}`}
                        </button>
                      )}
                      {success != null && <h3>{success}</h3>}
                    </div>
                  </div>
                </div>
              </form> */}
              <div className="border-style"></div>
            </div>
            <div className="vector-1">
              <img src={vec1} alt="" />
            </div>
            <div className="vector-2">
              <img src={vec2} alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default RSVP;
