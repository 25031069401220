import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Homepage from "../HomePage";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/:namepart" component={Homepage} />
          {/* <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2}/>
            <Route path='/home3' component={HomePage3}/>
            <Route path='/home4' component={HomePage4}/>
            <Route path='/home5' component={HomePage5}/>
            <Route path='/home6' component={HomePage6}/>
            <Route path='/cart' component={CartPage}/> */}
          {/* <Route path="/about" component={AboutPage} />
          <Route path="/story" component={StoryPage} />
          <Route path="/accomodation" component={AccomodationPage} />
          <Route path="/rsvp" component={RSVPPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/planner" component={PlannerPage} />
          <Route path="/groom-bride" component={BrideGroomPage} />
          <Route path="/pricing" component={PricingPage} />
          <Route path="/coming" component={ComingSoonPage} />
          <Route path="/portfolio-grid" component={PortfolioGridPage} />
          <Route path="/portfolio-masonary" component={PortfolioMasonaryPage} />
          <Route path="/shop" component={ShopPage} />
          <Route path="/service" component={ServicePage} />
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/order_received" component={OrderRecived} />
          <Route path="/product-single/:id" component={ProductSinglePage} />
          <Route path="/team-single/:id" component={TeamSinglePage} />
          <Route path="/project-single/:id" component={ProjectSinglePage} />
          <Route path="/service-single/:id" component={SeviceSinglePage} /> */}
          {/* <Route path="/404" component={ErrorPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/blog-single/:id" component={BlogDetails} />
          <Route
            path="/blog-single-left-sidebar/:id"
            component={BlogDetailsLeftSiide}
          />
          <Route
            path="/blog-single-fullwidth/:id"
            component={BlogDetailsFull}
          />
          <Route path="/blog" component={BlogPage} />
          <Route path="/blog-left-sidebar" component={BlogPageLeft} />
          <Route path="/blog-fullwidth" component={BlogPageFullwidth} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={SignUpPage} />
          <Route path="/forgot-password" component={ForgotPassword} /> */}
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
